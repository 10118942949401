<template>
  <div>
    <main class="form-signin mt-5">
      <component v-bind:is="currentComponent" @next="next" @previous="previous" :username="username" :code="code"></component>
      <p class="mt-5 mb-3 text-muted text-center">Phunkey Inc © 2022</p>
    </main>
  </div>
</template>

<script>
import { ref } from 'vue'
import ValidateUsernameEmail from '../components/pwd-reset/ValidateUsernameEmail.vue'
import ValidateCode from '../components/pwd-reset/ValidateCode.vue'
import ResetPwd from '../components/pwd-reset/ResetPassword.vue'
import Success from '../components/pwd-reset/Success.vue'
export default {
  components: {
    ValidateUsernameEmail,
    ValidateCode,
    ResetPwd,
    Success
  },
  setup () {
    const currentComponent = ref('ValidateUsernameEmail')
    const username = ref('')
    const code = ref('')
    const next = (payload) => {
      if (payload.nextComponent) {
        currentComponent.value = payload.nextComponent
        username.value = payload.username
        code.value = payload.code
      }
    }

    const previous = (payload) => {
      if (payload.previousComponent) {
        currentComponent.value = payload.previousComponent
      }
    }

    return {
      username,
      code,
      currentComponent,
      previous,
      next
    }
  }
}
</script>

<style scoped>
/*progressbar connectors*/
#progressbar li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: white;
  position: absolute;
  left: -50%;
  top: 9px;
  z-index: -1; /*put it behind the numbers*/
}

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before,
#progressbar li.active:after {
  background: #ee0979;
  color: white;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type='email'] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type='password'] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
