<template>
  <div @submit.prevent="submit">
    <img
      class="mb-5"
      src="@/assets/logo.png"
      style="height: 7.5rem;"
    />
    <hr />
    <h1 class="h1">Password reset successfully.</h1>
    <h4 class="h4">Redirecting to login page in a moment.</h4>

  </div>
</template>

<script>
import { onMounted } from '@vue/runtime-core'
import { useRouter } from 'vue-router'
export default {
  setup () {
    const router = useRouter()

    onMounted(() => {
      setTimeout(() => {
        router.push({ path: '/account/login' })
      }, 3000)
    })
  }
}
</script>

<style>

</style>
