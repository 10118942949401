<template>
  <form @submit.prevent="submit">
    <img
      class="mb-5"
      src="@/assets/logo.png"
      style="height: 7.5rem;"
    />
    <hr />
    <h1 class="h3 mb-3 fw-normal">Enter your new password and confirm it.</h1>

    <div class="form-floating mb-1">
      <input
        type="password"
        class="form-control"
        id="floatingInput"
        placeholder="Password"
        v-model="form.password"
        required
      />
      <label for="floatingInput">Password</label>
    </div>
    <div class="form-floating">
      <input
        type="password"
        class="form-control"
        placeholder="Confirm Password"
        v-model="form.confirmPassword"
        required
      />
      <label for="email">Confirm Password</label>
    </div>
    <div class="form-floating mt-2 mb-2" v-if="passwordScore">
      <div class="progress" v-if="passwordScore.percent > 0">
        <div
          class="progress-bar"
          role="progressbar"
          :style="{ width: passwordScore.percent + '%' }"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {{ passwordScore.status }}
        </div>
      </div>
      <div class="">
        <ul style="color: red">
          <li v-for="(error, index) in passwordScore.errors" :key="index">
            {{ error }}
          </li>
        </ul>
      </div>
    </div>
    <div class="btn-group mt-2" role="group" aria-label="Password Reset Button">
      <button type="button" @click="cancel" class="btn btn-warning">
        Cancel
      </button>
      <button type="submit" class="btn btn-primary">Submit</button>
    </div>
  </form>
</template>

<script>
import { onMounted, ref, watch } from 'vue'
import axios from 'axios'
import { PasswordMeter } from 'password-meter/dist/index.min.js'
import { useRouter } from 'vue-router'
export default {
  props: {
    code: {
      type: String,
      required: true
    },
    username: {
      type: String,
      required: true
    }
  },
  emits: ['previous', 'next'],
  setup (props, { emit }) {
    const router = useRouter()

    const form = ref({
      password: '',
      confirmPassword: '',
      username: props.username,
      code: props.code
    })

    watch(
      () => form.value.password,
      (val, oldVal) => {
        setTimeout(() => {
          passwordScore.value = getPasswordScore(form.value.password, false)
        //   console.log('password score is ', passwordScore.value)
        }, 1000)
      }
    )

    watch(() => form.value.confirmPassword,
      (val, oldVal) => {
        passwordScore.value = getPasswordScore(form.value.password, true)
      })

    const passwordScore = ref(null)
    const getPasswordScore = (password, checkConfirmPassword) => {
      passwordScore.value = null
      if (form.value.confirmPassword) {
        if (form.value.password !== form.value.confirmPassword) {
          return {
            score: -1,
            status: 'needs requirement(s)',
            errors: ['Confirm password not match.'],
            percent: 0
          }
        }
      }

      const result = new PasswordMeter(
        {
          minLength: 5,
          maxLength: 20,
          uppercaseLettersMinLength: 1,
          lowercaseLettersMinLength: 2,
          numbersMinLength: 1,
          symbolsMinLength: 1,
          //   include: ['a', '$'],
          exclude: ['Password', 'P@$$w0rd']
          //   startsWith: '1',
          //   endsWith: '$',
          //   includeOne: ['$']
        },
        {
          40: 'Very Weak', // 001 <= x <  040
          80: 'Weak', // 040 <= x <  080
          120: 'Medium', // 080 <= x <  120
          180: 'Strong', // 120 <= x <  180
          200: 'Very Strong', // 180 <= x <  200
          _: 'Perfect' //        x >= 200
        }
      ).getResult(password)
      return result
    }

    const submit = async () => {
      passwordScore.value = getPasswordScore(form.value.password, true)
      let requestResult = null
      let result = null
      switch (passwordScore.value.status) {
        case 'Strong':
        case 'Perfect':
        //   console.log('password score is ', passwordScore.value)
          requestResult = await axios.post(
            process.env.VUE_APP_API_URL + '/users/updatepwdwithcode',
            form.value,
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          )

          result = requestResult.data
          if (!result) {
            alert('Username and email combination is invalid.')
            return
          }

          emit('next', {
            nextComponent: 'Success'
          })
          break
        case 'needs requirement(s)':
          alert('Password requirements not met.')
          break
        default:
          alert('Please create a stronger password.')
          break
      }
    }

    const cancel = () => {
    //   emit('previous', {
    //     previousComponent: 'ValidateCode'
    //   })
      router.push({ path: '/account/login' })
    }

    onMounted(() => {
      console.log(JSON.stringify(new PasswordMeter().getResult('@xc5--WWb')))
    })

    return {
      form,
      submit,
      cancel,
      passwordScore
    }
  }
}
</script>

<style>
</style>
