<template>
  <form @submit.prevent="submit">
    <img
      class="mb-5"
      src="@/assets/logo.png"
      style="height: 7.5rem;"
    />
    <hr />
    <h1 class="h3 mb-3 fw-normal">Enter your username and email address</h1>

    <div class="form-floating mb-1">
      <input
        type="text"
        class="form-control"
        id="floatingInput"
        placeholder="Username"
        v-model="form.username"
        required
      />
      <label for="floatingInput">Username</label>
    </div>
    <div class="form-floating">
      <input
        type="email"
        class="form-control"
        id="email"
        placeholder="Email"
        v-model="form.email"
        required
      />
      <label for="email">Email Address</label>
    </div>

    <div class="btn-group mt-2" role="group" aria-label="Password Reset Button">
      <button type="button" @click="cancel" class="btn btn-warning">Cancel</button>
      <button type="submit" class="btn btn-primary">Next</button>
    </div>
  </form>
</template>

<script>
import { ref } from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router'
export default {
  emits: ['previous', 'next'],
  setup (props, { emit }) {
    const router = useRouter()
    const form = ref({
      username: '',
      email: '',
      applicationTitle: 'FileVault'
    })

    const submit = async () => {
      const requestResult = await axios.post(
        process.env.VUE_APP_API_URL +
            '/users/validateemail',
        form.value,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      const result = requestResult.data
      if (!result) {
        alert('Username and email combination is invalid.')
        return
      }

      emit('next', {
        nextComponent: 'ValidateCode',
        username: form.value.username
      })
    }

    const cancel = () => {
    //   emit('previous', {
    //     previousComponent: 'ValidateUsernameEmail'
    //   })
      router.push({ path: '/account/login' })
    }

    return {
      form,
      submit,
      cancel
    }
  }
}
</script>

<style>
</style>
